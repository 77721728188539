import { postAnnouncement } from "../../../api/createannouncement";
import { t } from "i18next";
import { clearDB } from "../../../utils/indexedDBService";



export function publishFun(
    additionalPhones, setAdditionalPhones, phoneErrors, setPhoneErrors,
    setIsSubmitted, setIsLoading, announcementName, description,
    selectedSubcategory, selectedLocation, videoLink, priceType, price,
    volumeUnit, isBarter, selectedCategory, userData, tagsArray, images, navigate, setShowErrorModal, showErrorModal, setErrorMessage
) {
    const handlePublish = async () => {
        setIsSubmitted(true);
        setIsLoading(true);

        if (!announcementName) {
            console.error(t("required_title"));
            setIsLoading(false);
            return;
        }
        if (!description) {
            console.error(t("required_description"));
            setIsLoading(false);
            return;
        }
        if (!price) {
            console.error(t("required_price"));
            setIsLoading(false);
            return;
        }
        if (!selectedLocation) {
            console.error(t("required_location"));
            setIsLoading(false);
            return;
        }
        if (!selectedSubcategory) {
            console.error(t("required_subcategory"));
            setIsLoading(false);
            return;
        }
        if (!tagsArray || tagsArray.length === 0) {
            console.error(t("required_tags"));
            setIsLoading(false);
            return;
        }

        const phoneErrorsList = additionalPhones.map(phone => !phone.trim());
        setPhoneErrors(phoneErrorsList);

        if (phoneErrorsList.some(error => error)) {
            console.error(t("empty_phone_numbers"));
            setIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append("title", announcementName);
        formData.append("video_url", videoLink || "");
        formData.append("description", description);
        formData.append("price_type", priceType);
        formData.append("price", priceType === "fixed" ? price : "");
        formData.append("measurement_unit", volumeUnit);
        formData.append("isBarter", isBarter ? "true" : "false");

        formData.append("category_id", selectedCategory);
        formData.append("subcategory_id", selectedSubcategory);
        formData.append("user_location_id", selectedLocation.address_id || "");

        formData.append("contactName", userData.name || "");
        formData.append("contactPhone", userData.phone_number || "");

        additionalPhones.forEach((phone, index) => {
            formData.append(`additionalPhones[${index}]`, phone);
        });

        tagsArray.forEach(tag => {
            formData.append("tags[]", tag.trim());
        });

        images.forEach(file => {
            formData.append("images", file);
        });

        try {
            const accessToken = localStorage.getItem("access_token");
            const response = await postAnnouncement(formData, accessToken);

            if (response?.announcement_id) {
                localStorage.removeItem("priceType");
                localStorage.removeItem("announcementName");
                localStorage.removeItem("videoLink");
                localStorage.removeItem("description");
                localStorage.removeItem("price");
                localStorage.removeItem("tagsArray");
                localStorage.removeItem("volumeUnit");
                localStorage.removeItem("selectedLocation");
                localStorage.removeItem("selectedCategory");
                localStorage.removeItem("selectedCategoryName");
                localStorage.removeItem("selectedSubcategory");
                localStorage.removeItem("selectedSubcategoryName");
                localStorage.removeItem("images");
                clearDB("savedImages")
                clearDB("savedImageFiles")

                setAdditionalPhones([]);
                setPhoneErrors([]);

                setShowErrorModal(true);
                setErrorMessage(t('createAdSuccess'));

                setTimeout(() => {
                    navigate(`/announcement/${response.announcement_id}`);
                }, 4000);
            } else {
                console.error(t("announcement_id_missing"));
            }
        } catch (error) {
            console.error(t("error_creating_announcement"), error);
        } finally {
            setIsLoading(false);
        }
    };

    return { handlePublish };
}