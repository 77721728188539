import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AdFormUI } from "./AdFormUI";

import { previewFun } from "../utils/previewFun";
import { publishFun } from "../utils/publishFun";
import { deleteAdressFun } from "../utils/deleteAdressFun";
import { tagsFun } from "../utils/tagsFun";
import { phoneFun } from "../utils/phoneFun";
import { changesFun } from "../utils/changesFun";
import { getUserData } from "../utils/getUserData";
import { useMenuButton } from "../utils/menuButton";
import { t } from "i18next";

import { getFromDB } from "../../../utils/indexedDBService";

import { units } from "../constants/units";

function AdForm() {
    const navigate = useNavigate();
    const [priceType, setPriceType] = useState(localStorage.getItem("priceType") || "fixed");
    const [announcementName, setAnnouncementName] = useState(localStorage.getItem("announcementName" || ""));
    const [videoLink, setVideoLink] = useState(localStorage.getItem("videoLink") || "");
    const [description, setDescription] = useState(localStorage.getItem("description") || "");
    const [price, setPrice] = useState(localStorage.getItem("price") || "");
    const [isBarter, setIsBarter] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(() => {
        return localStorage.getItem("selectedCategory") || "";
    });

    const [selectedSubcategory, setSelectedSubcategory] = useState(() => {
        return localStorage.getItem("selectedSubcategory") || "";
    });

    const [selectedLocation, setSelectedLocation] = useState(() => {
        const savedLocation = localStorage.getItem("selectedLocation");
        return savedLocation ? JSON.parse(savedLocation) : "";
    });
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            const savedImages = await getFromDB("savedImageFiles");
            if (savedImages) {
                setImages(savedImages);
            }
        };

        fetchImages();
    }, []);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [userData, setUserData] = useState(null);
    const [UserLocations, setUserLocations] = useState(null);
    const [tagsArray, setTagsArray] = useState(() => {
        const savedTags = localStorage.getItem("tagsArray");
        return savedTags ? JSON.parse(savedTags) : [];
    });
    const [customTags, setCustomTags] = useState('');
    const [additionalPhones, setAdditionalPhones] = useState([]);
    const [phoneErrors, setPhoneErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [volumeUnit, setVolumeUnit] = useState(() => {
        return localStorage.getItem("volumeUnit") || null;
    });
    const [menuVisibility, setMenuVisibility] = useState({
        menu: false,
        user: false,
        address: false,
        search: false,
        language: false,
    });
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchUserData = getUserData(userData, UserLocations, setUserData, setUserLocations);

    const toggleMenu = useMenuButton(setMenuVisibility);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    useEffect(() => {
        localStorage.setItem("tagsArray", JSON.stringify(tagsArray));
    }, [tagsArray]);

    useEffect(() => {
        localStorage.setItem("selectedLocation", JSON.stringify(selectedLocation));
    }, [selectedLocation]);

    useEffect(() => {
        localStorage.setItem("images", JSON.stringify(images));
    }, [images]);

    useEffect(() => {
        localStorage.setItem("priceType", priceType);
        localStorage.setItem("announcementName", announcementName || "");
        localStorage.setItem("videoLink", videoLink);
        localStorage.setItem("description", description);
        localStorage.setItem("price", price);
    }, [priceType, announcementName, videoLink, description, price]);

    const { handleImagesChange, handleVideoLinkChange, handleAnnouncementInputChange, handleCustomTagsChange, handleSelectCategory, handleDescriptionChange, handleVolumeChange, handleBarterToggle, handleSelectLocation } = changesFun(setAnnouncementName, setVideoLink, setDescription, setSelectedCategory, setSelectedSubcategory, setIsBarter, setImages, setSelectedLocation, setCustomTags, setVolumeUnit);
    const { handleDeletePhone, handleAddPhone } = phoneFun(setAdditionalPhones, additionalPhones, setPhoneErrors, phoneErrors);

    const handlePublish = () => {
        const errors = [];

        if (!announcementName) errors.push(t('announcementNameError'));
        if (!description) errors.push(t('announcementDescriptionError'));
        if (!price) errors.push(t('announcementPriceError'));
        if (!selectedLocation) errors.push(t('announcementAddressError'));
        if (!tagsArray || tagsArray.length === 0) errors.push(t('tagsArrayError'));
        if (!selectedSubcategory) errors.push(t('announcementCategoryError'));
        if (!volumeUnit) errors.push(t('announcementVolumeUnit'));

        if (errors.length > 0) {
            setErrorMessage(errors.map(error => `<p>${error}</p>`).join(""));
            setShowErrorModal(true);
            setIsSubmitted(true);
            return;
        }

        const convertedVolumeUnit = units[volumeUnit] || volumeUnit;

        publishFun(
            additionalPhones,
            setAdditionalPhones,
            phoneErrors,
            setPhoneErrors,
            setIsSubmitted,
            setIsLoading,
            announcementName,
            description,
            selectedSubcategory,
            selectedLocation,
            videoLink,
            priceType,
            price,
            convertedVolumeUnit,
            isBarter,
            selectedCategory,
            userData,
            tagsArray,
            images,
            navigate,
            setShowErrorModal,
            showErrorModal,
            setErrorMessage
        ).handlePublish();
    };

    const handleDeleteAddress = deleteAdressFun(setUserLocations, selectedLocation, setSelectedLocation);
    const { handleAddTag, handleRemoveTag } = tagsFun(customTags, setTagsArray, tagsArray, setCustomTags);
    const handlePreview = () => {
        const errors = [];

        if (!announcementName) errors.push(t('announcementNameError'));
        if (!description) errors.push(t('announcementDescriptionError'));
        if (!price) errors.push(t('announcementPriceError'));
        if (!selectedLocation) errors.push(t('announcementAddressError'));
        if (!tagsArray || tagsArray.length === 0) errors.push(t('tagsArrayError'));
        if (!selectedSubcategory) errors.push(t('announcementCategoryError'));
        if (!volumeUnit) errors.push(t('announcementVolumeUnit'));

        if (errors.length > 0) {
            setErrorMessage(errors.map(error => `<p>${error}</p>`).join(""));
            setShowErrorModal(true);
            setIsSubmitted(true);
            return;
        }

        setErrorMessage("");
        setShowErrorModal(false);

        previewFun(
            announcementName, description, price, priceType, images, videoLink,
            userData, selectedLocation, navigate, setIsLoading,
            additionalPhones, setAdditionalPhones, phoneErrors, setPhoneErrors,
            setIsSubmitted, selectedSubcategory, selectedCategory, isBarter, tagsArray, volumeUnit
        );
    };

    const handlePhoneChange = (index, value) => {
        const updatedPhones = [...additionalPhones];
        updatedPhones[index] = value;
        setAdditionalPhones(updatedPhones);

        const updatedErrors = [...phoneErrors];
        updatedErrors[index] = false;
        setPhoneErrors(updatedErrors);
    };

    return (
        AdFormUI(
            handleImagesChange,
            handleVideoLinkChange,
            handleAnnouncementInputChange,
            isSubmitted,
            customTags,
            handleCustomTagsChange,
            handleAddTag,
            handleRemoveTag,
            tagsArray,
            handleSelectCategory,
            handleDescriptionChange,
            priceType,
            setPriceType,
            price,
            setPrice,
            handleVolumeChange,
            handleBarterToggle,
            handlePublish,
            isLoading,
            handlePreview,
            userData,
            additionalPhones,
            phoneErrors,
            handlePhoneChange,
            handleDeletePhone,
            handleAddPhone,
            selectedLocation,
            UserLocations,
            handleSelectLocation,
            handleDeleteAddress,
            toggleMenu,
            menuVisibility,
            fetchUserData,
            showErrorModal,
            setShowErrorModal,
            errorMessage,
            selectedSubcategory
        )
    );
}

export default AdForm;