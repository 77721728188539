import React from 'react';
import LanguageItem from '../item/language_item';
import '../style/LanguageModal.css';
import { useTranslation } from 'react-i18next';

const LanguageModal = ({ selectedLanguage, onSelectLanguage }) => {
    const { t, i18n } = useTranslation();

    const languages = [
        { code: 'ru', name: t('languages.ru') },
        { code: 'kz', name: t('languages.kz') },
        { code: 'en', name: t('languages.en') },
        { code: 'cn', name: t('languages.cn') },
    ];

    const handleLanguageChange = (code) => {
        if (i18n.language !== code) {
            i18n.changeLanguage(code);
            localStorage.setItem('language', code);
            localStorage.setItem('volumeUnit', '');
            onSelectLanguage(code);
            window.location.reload();
        }
    };

    const modalClass = selectedLanguage === 'cn' ? 'language_modal cn_active' : 'language_modal';

    return (
        <div className={modalClass}>
            {languages.map(({ code, name }) => (
                <LanguageItem
                    key={code}
                    language={name}
                    isSelected={code === selectedLanguage}
                    onClick={() => handleLanguageChange(code)}
                />
            ))}
        </div>
    );
};

export default LanguageModal;