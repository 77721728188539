import React from 'react';

import '../style/AdForm.css';

import { AddForm } from './AddForm';
import { ContactInfo } from './ContactInfo';
import { AddButtons } from './AddButtons';

import AddressModal from '../../modals/location_modal/ui/Main';
import ErrorModal from '../../modals/error_modal/ui/Main';

export function AdFormUI(handleImagesChange, handleVideoLinkChange, handleAnnouncementInputChange, isSubmitted, customTags, handleCustomTagsChange, handleAddTag, handleRemoveTag, tagsArray, handleSelectCategory, handleDescriptionChange, priceType, setPriceType, price, setPrice, handleVolumeChange, handleBarterToggle, handlePublish, isLoading, handlePreview, userData, additionalPhones, phoneErrors, handlePhoneChange, handleDeletePhone, handleAddPhone, selectedLocation, UserLocations, handleSelectLocation, handleDeleteAddress, toggleMenu, menuVisibility, fetchUserData, showErrorModal, setShowErrorModal, errorMessage, selectedSubcategory) {
    return <div className="ad_form_container">
        {AddForm(handleImagesChange, handleVideoLinkChange, handleAnnouncementInputChange, isSubmitted, customTags, handleCustomTagsChange, handleAddTag, handleRemoveTag, tagsArray, handleSelectCategory, handleDescriptionChange, priceType, setPriceType, price, setPrice, handleVolumeChange, handleBarterToggle, handlePublish, isLoading, handlePreview, selectedSubcategory)}
        <ContactInfo
            userData={userData || {}}
            additionalPhones={additionalPhones}
            phoneErrors={phoneErrors}
            handlePhoneChange={handlePhoneChange}
            handleDeletePhone={handleDeletePhone}
            handleAddPhone={handleAddPhone}
            isSubmitted={isSubmitted}
            selectedLocation={selectedLocation}
            UserLocations={UserLocations}
            handleSelectLocation={handleSelectLocation}
            handleDeleteAddress={handleDeleteAddress}
            toggleMenu={toggleMenu}
        />
        {AddButtons(handlePublish, isLoading, handlePreview)}
        <AddressModal menuVisible={menuVisibility.address} toggleMenu={() => toggleMenu('address')} onSubmitted={() => fetchUserData()} />
        <ErrorModal showLogoutModal={showErrorModal} setShowLogoutModal={setShowErrorModal} message={errorMessage} />
    </div>;
}