import React from 'react'
import AdForm from '../components/AdForm';
import Header from '../../main/components/Header';
import Fooder from '../../main/components/Fooder';
import MobileBottomBar from '../../main/components/MobileBottomBar';
import Path from '../../path/ui/Path';
import { t } from 'i18next';

function Main() {
  return (
    <div>
      <Header />
      <Path currentPath={t('createAnnouncement')} />
      <AdForm />
      <Fooder />
      <MobileBottomBar activeTabName={'add'} />
    </div>
  )
}

export default Main